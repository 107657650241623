
import { onMounted, ref, watch } from "vue";
import useProviderAdvance from "@/modules/providerAdvances/composables/useProviderAdvance";
import usePanel from "@/composables/usePanel";
import { baseURL } from "@/api/erpApi";
import useAuth from "@/modules/auth/composables/useAuth";

const { providerAdvanceForm, disabledStatus } = useProviderAdvance();
const { username } = useAuth();

export default {
    props: ["catalogs"],
    setup() {
        const { showPanel, togglePanel } = usePanel();
        const providerId = ref("");
        const date = ref("");
        const user = ref("");
        const amount = ref(0);
        const accountingCenterId = ref("");
        const status = ref("");
        const observations = ref("");

        const select2center = {
            ajax: {
                url: () => baseURL + "/accounting_centers/select2",
            },
        };

        const select2provider = {
            ajax: {
                url: () => baseURL + "/providers/select2",
            },
        };

        const datepickerConfig = {
            singleDatePicker: true,
            showDropdowns: true,
            width: "100%",
            locale: {
                format: "DD/MM/YYYY",
            },
            maxDate: new Date(),
        };

        onMounted(() => {
            providerId.value = providerAdvanceForm.value.providerId;
            date.value = providerAdvanceForm.value.date;
            user.value = username.value;
            amount.value = providerAdvanceForm.value.amount;
            accountingCenterId.value = providerAdvanceForm.value.accountingCenterId;
            status.value = providerAdvanceForm.value.status;
            observations.value = providerAdvanceForm.value.observations;
        });

        watch(providerId, (val) => (providerAdvanceForm.value.providerId = val));
        watch(date, (val) => (providerAdvanceForm.value.date = val));
        watch(amount, (val) => (providerAdvanceForm.value.amount = val));
        watch(accountingCenterId, (val) => (providerAdvanceForm.value.accountingCenterId = val));
        watch(status, (val) => (providerAdvanceForm.value.status = val));
        watch(observations, (val) => (providerAdvanceForm.value.observations = val));

        return {
            select2center,
            select2provider,
            showPanel,
            togglePanel,
            providerId,
            date,
            amount,
            accountingCenterId,
            status,
            providerAdvanceForm,
            disabledStatus,
            username,
            user,
            datepickerConfig,
            observations,
        };
    },
};

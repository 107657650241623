
import GeneralData from "@/modules/providerAdvances/components/GeneralData.vue";
import useProviderAdvance from "@/modules/providerAdvances/composables/useProviderAdvance";
import toastr from "toastr";
import {ref} from "vue";
import router from "@/router";
export default {
    components: {GeneralData},
    setup() {
        const {clearForm, providerAdvanceForm, createProviderAdvance, catalogs} = useProviderAdvance()
        const sending = ref(false)
        clearForm();
        return {
            sending,
            catalogs,
            cancel: () => router.push({name: 'provider_advances'}),
            onSubmit: async () => {
                try {
                    sending.value = true
                    const {ok, message} = await createProviderAdvance(providerAdvanceForm.value)
                    if (ok) {
                        toastr.success('Su solicitud se ha procesado correctamente.')
                        await router.push({name: 'provider_advances'})
                    } else {
                        await toastr.error(message)
                    }
                } finally {
                    sending.value = false
                }
            }
        }
    }
}
